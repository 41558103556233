const defaultImage = {
  color: {
    hex: '#cfcfd0',
    isDark: false,
    isLight: true,
  },
  extension: 'jpeg',
  filename: 'Kits_PDP_17885fe3-8e17-484b-9500-b449d9f9272a',
  height: 1000,
  id: 'gid://shopify/GenericFile/22176489603160',
  isImage: true,
  isSvg: null,
  isFile: null,
  mime: 'image/jpeg',
  palette: ['#222226', '#dadadb', '#8c8c8f', '#84848c'],
  ratio: 0.8,
  size: 16546,
  thumbnail:
    'https://cdn.shopify.com/s/files/1/1368/3463/files/Kits_PDP_17885fe3-8e17-484b-9500-b449d9f9272a_300x_crop_center.jpeg?v=1654280301',
  src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/Kits_PDP_17885fe3-8e17-484b-9500-b449d9f9272a.jpeg?v=1654280301',
  width: 800,
};

export const Schema = ({ page }) => {
  if (!page) return null;

  return {
    label: 'Kits Collection',
    key: 'kits-collection',
    fields: [
      {
        name: 'kits',
        label: 'Kits',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        defaultItem: {
          title: 'New Kit',
          price: 100,
          qty: 3,
          dateTimes: {
            startDate: '2022-01-01T00:00:00Z',
            startTime: 12,
            endDate: '2022-01-02T00:00:00Z',
            endTime: 12,
          },
          image: defaultImage,
        },
        fields: [
          {
            label: 'Kit Handle',
            name: 'handle',
            component: 'text',
            description: 'e.g. "festival-kit',
          },
          {
            label: 'Kit Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Kit Price (USD)',
            name: 'price',
            component: 'number',
          },
          {
            label: 'Compare At Price (USD)',
            name: 'compareAtPrice',
            component: 'number',
          },
          {
            label: 'Pack Quantity',
            name: 'qty',
            component: 'number',
          },
          {
            label: 'Start & End DateTimes',
            name: 'dateTimes',
            component: 'group',
            fields: [
              {
                label: 'Enable',
                name: 'enable',
                component: 'toggle',
                toggleLabels: {
                  true: true,
                  false: false,
                },
                defaultValue: true,
              },
              {
                label: 'Start Date',
                name: 'startDate',
                component: 'date',
                description: 'Date kit automatically goes live',
                dateFormat: true,
              },
              {
                label: 'Start Time (Pacific Time)',
                name: 'startTime',
                component: 'number',
                description:
                  '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm',
              },
              {
                label: 'End Date',
                name: 'endDate',
                component: 'date',
                description: 'Date kit automatically ends',
                dateFormat: true,
              },
              {
                label: 'End Time (Pacific Time)',
                name: 'endTime',
                component: 'number',
                description:
                  '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm',
              },
            ],
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            name: 'products',
            label: 'Products',
            description: 'Products selection only for data layer purposes',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
          },
        ],
      },
    ],
  };
};
