import Script from 'next/script';

export const FueledOneTrustScript = () => {
  return (
    <>
      {
        // OneTrust Cookies Consent Notice start for www.cutsclothing.com
      }
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="deb9b185-5c50-428a-afed-96d8bcdfe987"
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          function OptanonWrapper() { }
        `,
        }}
      />
      {
        // OneTrust Cookies Consent Notice end for www.cutsclothing.com
      }
    </>
  );
};
