import { sectionWithHeight } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Social Snowball Embed Form',
    key: 'social-snowball-embed-form',
    fields: [
      {
        label: 'Form Id',
        name: 'formId',
        component: 'text',
        defaultValue: 'f92ea309-b604-4100-847c-7cbb2e864cd0',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          mobile: {
            maxHeight: null,
            minHeight: 210,
            mt: 'm',
          },
          desktop: {
            maxHeight: null,
            minHeight: 450,
            mt: 'm',
          },
        },
      },
    ],
  };
};
