import Script from 'next/script';
import { Container, Heading } from 'theme-ui';
import { Section } from '@snippets';
import { Schema } from './SocialSnowballEmbedForm.schema';

export const SocialSnowballEmbedForm = ({ cms }) => {
  const { section, heading, formId } = cms;

  return (
    <Section section={section}>
      <Container
        data-comp={SocialSnowballEmbedForm.displayName}
        sx={{
          minHeight: [
            `${section?.mobile?.minHeight}px`,
            `${section?.desktop?.minHeight}px`,
          ],
          maxHeight: [
            `${section?.mobile?.maxHeight}px`,
            `${section?.desktop?.maxHeight}px`,
          ],
        }}
      >
        {heading && (
          <Heading
            variant="text.h4"
            as="h2"
            sx={{
              textAlign: 'center',
              mb: 10,
            }}
          >
            {heading}
          </Heading>
        )}

        <iframe
          title="affiliate-sign-up-form"
          id="affiliate-sign-up-form"
          src={`https://api.socialsnowball.io/register-form/15901/${formId}`}
          style={{
            border: 'none',
            width: '100%',
            height: '100vh',
          }}
        />
        <Script
          dangerouslySetInnerHTML={{
            _html: `!async function(){let e=document.querySelector("#affiliate-sign-up-form");window.addEventListener("message",t=>{t.origin===new URL(e.getAttribute("src")).origin&&(e.style.height=t.data.height)})}();`,
          }}
        />
      </Container>
    </Section>
  );
};

SocialSnowballEmbedForm.displayName = 'SocialSnowballEmbedForm';
SocialSnowballEmbedForm.Schema = Schema;
