export const Schema = ({ page }) => {
  if (!page) return null;

  return {
    label: 'Kit',
    key: 'kit',
    fields: [
      {
        label: 'Kit Title',
        name: 'title',
        component: 'text',
        defaultValue: 'New Kit',
      },
      {
        label: 'Kit Description',
        name: 'description',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        label: 'Kit Price (USD)',
        name: 'price',
        component: 'number',
        defaultValue: 100,
      },
      {
        label: 'Compare At Price Override (USD)',
        name: 'compareAtPrice',
        component: 'number',
        description: 'Overrides the compare at price calculated automatically',
      },
      {
        label: 'Products',
        name: 'products',
        component: 'group',
        fields: [
          {
            name: 'list',
            label: 'Products',
            description: 'Max of 6 products per kit',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: {
                handle: 'black-crew-curve-hem',
              },
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: {
                  handle: 'black-crew-curve-hem',
                },
              },
              {
                product: {
                  handle: 'black-ao-pant',
                },
              },
              {
                product: {
                  handle: 'black-crew-curve-hem',
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Start & End DateTimes',
        name: 'dateTimes',
        component: 'group',
        fields: [
          {
            label: 'Enable',
            name: 'enable',
            component: 'toggle',
            toggleLabels: {
              true: true,
              false: false,
            },
            defaultValue: true,
          },
          {
            label: 'Start Date',
            name: 'startDate',
            component: 'date',
            description:
              'Date kit automatically goes live. Refresh page to observe update',
            dateFormat: true,
            defaultValue: '2022-01-01T00:00:00Z',
          },
          {
            label: 'Start Time (Pacific Time)',
            name: 'startTime',
            component: 'number',
            description:
              '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm. Refresh page to observe update',
            defaultValue: 12.0,
          },
          {
            label: 'End Date',
            name: 'endDate',
            component: 'date',
            description:
              'Date kit automatically ends. Refresh page to observe update',
            dateFormat: true,
            defaultValue: '2022-01-02T00:00:00Z',
          },
          {
            label: 'End Time (Pacific Time)',
            name: 'endTime',
            component: 'number',
            description:
              '24-hour format. Add minutes as decimals. e.g. "14.30" equals 2:30pm. Refresh page to observe update',
            defaultValue: 12.0,
          },
        ],
      },
      {
        label: 'Images',
        name: 'images',
        component: 'group',
        fields: [
          {
            label: 'Images',
            name: 'list',
            component: 'group-list',
            itemProps: {
              label: '{{item.image?.filename}}',
            },
            defaultItem: {
              alt: 'Product image',
            },
            fields: [
              {
                label: 'Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of image',
              },
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
            ],
            defaultValue: [
              {
                alt: 'Product image',
                image: {
                  color: {
                    hex: '#cfcfd0',
                    isDark: false,
                    isLight: true,
                  },
                  extension: 'jpeg',
                  filename: 'Kits_PDP_17885fe3-8e17-484b-9500-b449d9f9272a',
                  height: 1000,
                  id: 'gid://shopify/GenericFile/22176489603160',
                  isImage: true,
                  isSvg: null,
                  isFile: null,
                  mime: 'image/jpeg',
                  palette: ['#222226', '#dadadb', '#8c8c8f', '#84848c'],
                  ratio: 0.8,
                  size: 16546,
                  thumbnail:
                    'https://cdn.shopify.com/s/files/1/1368/3463/files/Kits_PDP_17885fe3-8e17-484b-9500-b449d9f9272a_300x_crop_center.jpeg?v=1654280301',
                  src: 'https://cdn.shopify.com/s/files/1/1368/3463/files/Kits_PDP_17885fe3-8e17-484b-9500-b449d9f9272a.jpeg?v=1654280301',
                  width: 800,
                },
              },
            ],
          },
        ],
      },
    ],
  };
};
