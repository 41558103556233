import { useEffect, useState, useCallback } from 'react';
import Script from 'next/script';
import { useEventQueue } from './useEventQueue';

export const FueledKlaviyoScript = ({ withKey: klaviyoPixelId }) => {
  /* Specs:
   * https://developers.klaviyo.com/en/docs/javascript_api
   * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration
   */
  const { getEvents, storeEvents } = useEventQueue();

  const [count, setCount] = useState(0);
  const [klaviyoLoaded, setKlaviyoLoaded] = useState(0);

  useEffect(() => {
    const t = window.setInterval(() => {
      if (count > 10) {
        clearInterval(t);
      }
      if (typeof window.klaviyo === 'object') {
        clearInterval(t);
        setKlaviyoLoaded(true);
      } else {
        setCount((prev) => prev + 1);
      }
    }, 300);
    return () => clearInterval(t);
  }, [count]);

  const identify = useCallback((event) => {
    // Identify a user
    if (event.detail?.user_properties?.customer_email) {
      window?.klaviyo.identify({
        email: event.detail.user_properties.customer_email,
        first_name: event.detail.user_properties.customer_first_name,
        last_name: event.detail.user_properties.customer_last_name,
      });
    }
  }, []);

  const handleViewItemList = useCallback(
    (event) => {
      identify(event);
      const collectionId = event.detail?.ecommerce?.collection_id;
      window?.klaviyo.push([
        'track',
        'Category View',
        {
          CategoryName: event.detail.ecommerce.collection_id, // Best we got at the moment.
          CategoryID: collectionId,
        },
      ]);
    },
    [identify]
  );

  const handleViewItem = useCallback(
    (event) => {
      identify(event);
      const [product] = event.detail?.ecommerce?.detail?.products || [];
      if (!product) return;

      window?.klaviyo.push([
        'track',
        'Viewed Product',
        {
          ProductName: product.name,
          ProductID: product.product_id,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          CompareAtPrice: product.price,
        },
      ]);

      window?.klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.product_id,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            CompareAtPrice: product.compare_at_price,
          },
        },
      ]);
    },
    [identify]
  );

  const handleAddToCart = useCallback(
    (event) => {
      identify(event);
      const [product] = event.detail?.ecommerce?.add?.products || [];
      if (!product) return;

      const item = {
        Name: product.name,
        ProductID: product.product_id,
        Categories: product.categories,
        ImageURL: product.image,
        URL: document.location.href,
        Brand: product.brand,
        Price: product.price,
        Quantity: product.quantity,
        CompareAtPrice: product.price,
      };

      window?.klaviyo.push(['track', 'Added to Cart', item]);

      window?.klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.product_id,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            Quantity: product.quantity,
            CompareAtPrice: product.compare_at_price,
          },
        },
      ]);
    },
    [identify]
  );

  const handleKlaviyoForms = useCallback((event) => {
    if (event.detail.type === 'submit') {
      window.dataLayer.push({
        event: 'dl_subscribe',
        formId: event.detail.formId,
        formTitle: event.detail.metaData.$source,
      });
    }
  }, []);

  const handleViewSearchResults = useCallback(
    (event) => {
      identify(event);
      const products = event.detail?.ecommerce?.products || [];
      const searchTerm =
        event.detail?.ecommerce?.actionField?.search_term || '';

      window?.klaviyo.push([
        'track',
        'Searched Site',
        {
          SearchTerm: searchTerm,
          ReturnedResults: products.length,
          ReturnedProducts: products.map((p) => ({
            Name: p.name,
            ProductID: p.product_id,
            Price: p.price,
            Categories: p.category,
          })),
        },
      ]);
    },
    [identify]
  );

  useEffect(() => {
    function makeListener(handler) {
      return function eventHandler(e) {
        const evt = e;
        if (!klaviyoLoaded) {
          storeEvents(evt.type, [evt]);
        } else {
          handler(evt);
        }
      };
    }

    const viewItemListener = makeListener(handleViewItem);
    const viewItemListListener = makeListener(handleViewItemList);
    const addToCartListener = makeListener(handleAddToCart);
    const klaviyoFormsListener = makeListener(handleKlaviyoForms);
    const viewSearchResultsListener = makeListener(handleViewSearchResults);

    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_view_item_list', viewItemListListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);
    window.addEventListener('klaviyoForms', klaviyoFormsListener);
    window.addEventListener(
      'dl_view_search_results',
      viewSearchResultsListener
    );

    return () => {
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_view_item_list', viewItemListListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
      window.removeEventListener('klaviyoForms', klaviyoFormsListener);
      window.removeEventListener(
        'dl_view_search_results',
        viewSearchResultsListener
      );
    };
  }, [klaviyoLoaded]);

  useEffect(() => {
    if (!klaviyoLoaded) return;

    const eventTypes = [
      'dl_view_item',
      'dl_view_item_list',
      'dl_add_to_cart',
      'klaviyoForms',
      'dl_view_search_results',
    ];

    eventTypes.forEach((type) => {
      const queued = getEvents(type);
      if (!queued.length) return;

      switch (type) {
        case 'dl_view_item':
          queued.forEach((evt) => handleViewItem(evt));
          break;
        case 'dl_view_item_list':
          queued.forEach((evt) => handleViewItemList(evt));
          break;
        case 'dl_add_to_cart':
          queued.forEach((evt) => handleAddToCart(evt));
          break;
        case 'klaviyoForms':
          queued.forEach((evt) => handleKlaviyoForms(evt));
          break;
        case 'dl_view_search_results':
          queued.forEach((evt) => handleViewSearchResults(evt));
          break;
        default:
          break;
      }
    });
  }, [klaviyoLoaded]);

  return (
    <Script
      type="text/javascript"
      id="klaviyo-script"
      defer
      strategy="beforeInteractive"
      src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPixelId}`}
    />
  );
};
