import { useState, useMemo, useCallback } from 'react';
import { Box, Paragraph } from 'theme-ui';

import { Countdown } from '@snippets';

export const Timer = ({ dateTimes, status, setStatus }) => {
  const {
    enable = true,
    startDate,
    startTime,
    endDate,
    endTime,
  } = dateTimes || {};

  const [message, setMessage] = useState('');

  const getFormattedDate = useCallback((date, time) => {
    const gmtOffset =
      new Date(date.replace('T00', 'T07')).getTimezoneOffset() / 60;
    const baseTimeInMs = new Date(
      date.replace('T00', `T0${gmtOffset}`)
    ).getTime();
    const addedTimeInMin =
      Math.floor(time) * 60 + parseFloat(time.toFixed(2).split('.')[1]);
    const finalTimeInMs = baseTimeInMs + addedTimeInMin * 60 * 1000;

    return new Date(finalTimeInMs);
  }, []);

  const formattedDates = useMemo(() => {
    return {
      start: getFormattedDate(startDate, startTime),
      end: getFormattedDate(endDate, endTime),
    };
  }, [startDate, startTime, endDate, endTime]);

  if (!enable) {
    setStatus('live');
  }

  if (!enable || !startDate || !startTime || !endDate || !endTime) return null;

  return (
    <Box data-comp={Timer.displayName} sx={{ mb: 6 }}>
      <Paragraph
        sx={{
          fontWeight: 400,
          fontSize: 2,
          lineHeight: '12px',
        }}
      >
        {status ? `${message}${' '}` : ''}
        <Countdown
          startTime={formattedDates.start}
          endTime={formattedDates.end}
          status={status}
          style={{
            fontWeight: 600,
            fontSize: 3,
            display: 'inline',
          }}
          actions={{
            setEnded: () => {
              setStatus('ended');
              setMessage('Kit has expired.');
            },
            setAwaiting: () => {
              setStatus('awaiting');
              setMessage('Kit goes live in');
            },
            setLive: () => {
              setStatus('live');
              setMessage('Kit expires in');
            },
          }}
        />
      </Paragraph>
    </Box>
  );
};

Timer.displayName = 'Timer';
