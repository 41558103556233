import { useRef } from 'react';

export function useEventQueue() {
  const eventQueueRef = useRef({});

  const getEvents = (eventName) => {
    return eventQueueRef.current[eventName] || [];
  };

  const storeEvents = (eventName, events) => {
    if (!eventQueueRef.current[eventName]) {
      eventQueueRef.current[eventName] = [];
    }

    eventQueueRef.current[eventName].push(...events);
  };

  return {
    getEvents,
    storeEvents,
  };
}
