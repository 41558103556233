import { useMemo } from 'react';
import { FueledBingScript } from './FueledBingScript';
import { FueledClientScript } from './FueledClientScript';
import { FueledAttentiveScript } from './FueledAttentiveScript';
import { FueledCuVimeoTracking } from './FueledCuVimeoTracking';
import { FueledGtmScript } from './FueledGtmScript';
import { FueledImpactScript } from './FueledImpactScript';
import { FueledKlaviyoScript } from './FueledKlaviyoScript';
import { FueledKustomerScript } from './FueledKustomerScript';
import { FueledNorthbeamScript } from './FueledNorthbeamScript';
import { FueledTikTokScript } from './FueledTikTokScript';
import { FueledApplovinScript } from './FueledApplovinScript';
import { FueledOneTrustScript } from './FueledOneTrustScript';

const gtmContainerId =
  /* process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || */ 'GTM-MH6NHVPD';
const attentiveShopId =
  process.env.NEXT_PUBLIC_ATTENTIVE_SHOP_ID || 'cutsclothing';
const bingId = process.env.NEXT_PUBLIC_BING_KEY || '25044392';
const impactPixelId =
  process.env.NEXT_PUBLIC_IMPACT_KEY || 'A5336971-0704-4a1e-a4fa-a11dcfc9d8581';
const klaviyoId = process.env.NEXT_PUBLIC_KLAVIYO_KEY || 'HhTxnW';
const kustomerApiKey =
  process.env.NEXT_PUBLIC_KUSTOMER_API_KEY ||
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYWVhY2IzMjM0OTg3NDRiYmIxMzFkOSIsInVzZXIiOiI2MWFlYWNiMjdlNTJkYjI1ZmJmYzRlZDgiLCJvcmciOiI1ZWM2ODE1YTIwNDIwZjAwMTIyYjRjZTQiLCJvcmdOYW1lIjoiY3V0c2Nsb3RoaW5nIiwidXNlclR5cGUiOiJtYWNoaW5lIiwicG9kIjoicHJvZDEiLCJyb2xlcyI6WyJvcmcudHJhY2tpbmciXSwiYXVkIjoidXJuOmNvbnN1bWVyIiwiaXNzIjoidXJuOmFwaSIsInN1YiI6IjYxYWVhY2IyN2U1MmRiMjVmYmZjNGVkOCJ9.kVVKzGznUNJiThsZQg3_AwQw56R909jF3P_SOzR9c6c';
const tiktokPixelId =
  process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || 'CA20VLRC77UB6IBHVS9G';
const applovinEventKey =
  process.env.NEXT_PUBLIC_APPLOVIN_EVENT_KEY ||
  '5e1b2659-d207-47f5-8dd7-5863f57b1164';
const applovinCategoryId = process.env.PUBLIC_APPLOVIN_CATEGORY_ID || 1604;

export const Fueled = () => {
  // const userCanBeTracked = useTrackingConsent();

  const scripts = useMemo(
    () => [
      { Component: FueledOneTrustScript },
      { withKey: gtmContainerId, Component: FueledGtmScript },
      { withKey: attentiveShopId, Component: FueledAttentiveScript },
      { withKey: bingId, Component: FueledBingScript },
      { withKey: impactPixelId, Component: FueledImpactScript },
      { withKey: klaviyoId, Component: FueledKlaviyoScript },
      { withKey: kustomerApiKey, Component: FueledKustomerScript },
      { withKey: tiktokPixelId, Component: FueledTikTokScript },
      {
        withKey: { applovinEventKey, applovinCategoryId },
        Component: FueledApplovinScript,
      },
      { Component: FueledCuVimeoTracking },
      { Component: FueledNorthbeamScript },
    ],
    []
  );

  // if (!userCanBeTracked) return null;

  return (
    <>
      <FueledClientScript />
      {/* Uncomment the following line to enable the FueledDlDebug component */}
      {/* <FueledDlDebug /> */}
      {scripts.map(({ withKey, Component, key }, index) => (
        <Component withKey={withKey || ''} key={key || index} />
      ))}
    </>
  );
};

export default Fueled;
