import {
  AmbassadorPerks,
  AmbassadorHow,
  AmbassadorSlider,
  AmbassadorVideo,
} from './ambassador';
import { FAQSection } from './FAQSection';
import { Button } from './Button';
import { CollectionGrid } from './CollectionGrid';
import { CollectionsSidebarSection } from './CollectionsSidebar';
import { CollectionSubCategory } from './CollectionSubCategory';
import { Comparison } from './Comparison';
import { CountdownTimerBanner } from './CountdownTimerBanner';
import { EmailSignup } from './EmailSignup';
import { FeaturedText } from './FeaturedText';
import { FeaturedSlider } from './FeaturedSlider';
import { FormBuilder } from './FormBuilder';
import { FourTile } from './FourTile';
import { FridayProjects } from './FridayProjects';
import { HalfMedia } from './HalfMedia';
import { HalfMediaVIP } from './HalfMediaVIP';
import { Hero } from './Hero';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { Kit } from './Kit';
import { KitsCollection } from './KitsCollection';
import { Markdown } from './Markdown';
import { Marquee } from './Marquee';
import { MembershipHow } from './membership/MembershipHow';
import { MobileNav } from './MobileNav';
import { NarvarTracking } from './NarvarTracking';
import { NewArrivals } from './NewArrivals';
import { ParallaxHero } from './ParallaxHero';
import { PostscriptSubscribe } from './PostscriptSubscribe';
import { ProductEducation } from './ProductEducation';
import { ProductMeta } from './ProductMeta';
import { ProductRecs } from './ProductRecs';
import { ProductsSlider } from './ProductsSlider';
import { Returns } from './Returns';
import { Social } from './SocialIG';
import { SocialSnowballEmbedForm } from './SocialSnowballEmbedForm';
import { SuperCollection } from './SuperCollection';
import { Testimonials } from './Testimonials';
import { TestimonialsVIP } from './TestimonialsVIP';
import { ThreeTile } from './ThreeTile';
import { TwoTile } from './TwoTile';
import { VideoSection } from './VideoSection';
import { YotpoRewards } from './YotpoRewards';
import { ShopByCut } from './ShopByCut';
import { SectionFourZeroFour } from './SectionFourZeroFour';
import { TextBlock } from './TextBlock';
import { TypeFormWidget } from './TypeFormWidget';
import { YouTubeEmbedVideo } from './YouTubeEmbedVideo';
// import { YotpoReviews } from './YotpoReviews';
import { KlaviyoSubscribe } from './KlaviyoSubscribe';

export default [
  AmbassadorPerks,
  AmbassadorHow,
  AmbassadorSlider,
  AmbassadorVideo,
  Button,
  CollectionGrid,
  CollectionsSidebarSection,
  CollectionSubCategory,
  Comparison,
  CountdownTimerBanner,
  EmailSignup,
  FeaturedText,
  FeaturedSlider,
  FormBuilder,
  FourTile,
  FridayProjects,
  HalfMedia,
  HalfMediaVIP,
  Hero,
  Html,
  IconRow,
  Image,
  Kit,
  KitsCollection,
  KlaviyoSubscribe,
  ProductEducation,
  Markdown,
  Marquee,
  MembershipHow,
  MobileNav,
  NarvarTracking,
  NewArrivals,
  ParallaxHero,
  PostscriptSubscribe,
  ProductMeta,
  ProductRecs,
  ProductsSlider,
  Returns,
  SectionFourZeroFour,
  ShopByCut,
  Social,
  SocialSnowballEmbedForm,
  SuperCollection,
  Testimonials,
  TestimonialsVIP,
  ThreeTile,
  TwoTile,
  VideoSection,
  FAQSection,
  YotpoRewards,
  TextBlock,
  TypeFormWidget,
  Button,
  YouTubeEmbedVideo,
  // YotpoReviews,
];
